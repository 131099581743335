/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

export const onClientEntry = () => {
    let logoStorage = localStorage.getItem("overlayShow");
    let alertStorage = localStorage.getItem("alertShow");
    let fraudPageStorage = localStorage.getItem("fraudPage");
    let promotePopup = localStorage.getItem('promotePopup');
    let promoteShowed = localStorage.getItem('promoteShowed');



    window.onload = () => {
        console.log('WINDOW IS LOAD')
        if(logoStorage) {
            localStorage.removeItem("overlayShow")
        }

        if(alertStorage) {
            localStorage.removeItem("alertShow")
        }

        if(fraudPageStorage) {
            localStorage.removeItem("fraudPage")
        }

        if(promotePopup) {
            localStorage.removeItem("promotePopup")
        }

        if(promoteShowed) {
            localStorage.removeItem("promoteShowed")
        }
    }
  }