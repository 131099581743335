// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-product-slug-js": () => import("/opt/build/repo/src/templates/productSlug.js" /* webpackChunkName: "component---src-templates-product-slug-js" */),
  "component---src-templates-post-slug-js": () => import("/opt/build/repo/src/templates/postSlug.js" /* webpackChunkName: "component---src-templates-post-slug-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("/opt/build/repo/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-fraud-alert-js": () => import("/opt/build/repo/src/pages/fraud-alert.js" /* webpackChunkName: "component---src-pages-fraud-alert-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("/opt/build/repo/src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-products-js": () => import("/opt/build/repo/src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-profile-js": () => import("/opt/build/repo/src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */),
  "component---src-pages-styles-notfound-styled-js": () => import("/opt/build/repo/src/pages/styles/notfoundStyled.js" /* webpackChunkName: "component---src-pages-styles-notfound-styled-js" */)
}

